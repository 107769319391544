import React from "react";
import './contact.css';

const Contact: React.FC = () => {
    return(
        <>
            <div className="contactcontainer">
                <h1>Get in touch!</h1>
            </div>
        </>
    );
}

export default Contact;