import React from "react";
import './experience.css';

const Experience: React.FC = () => {
    return(
        <>
            <section className="experiencecontainer">
                <h2>
                    Currently no relevant work experience.. the search continues..
                </h2>
            </section>
        </>
    );
}

export default Experience;